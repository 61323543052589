import { IOption } from 'ng-select';
import { UserModels } from './user.model';
export class PIList {
  index: number;
  POHeaderID: number;
  PIDocNumber: string;
  Status: number;
  Table1: Array<Table1>;
  SAPPODocNumber: string;
  SAPSODocNumber: string;
  AgmNo: string;
  PlantCode: string;
  PurGroupCode: string;
  Table2: Array<Table2>;
  UpdateBy: number;
  RejectReason: string;
  POApproverID: any;
  POApproveID: any;
  PODocTypeGroup: any;
  PODocTypeID: any;
  ReleaseCode: string;
  PositionListOption: Array<IOption>;
  CpoPosition: any;
  PRDocType: string;
  PRNumber: any;
  constructor() {
    this.Table1 = new Array<Table1>();
    this.Table2 = new Array<Table2>();
  }
}

export class Table1 {
  POTitle: string;
  Buyer: number;
  BuyerName: string;
  SupplierName: string;
  CreateDate: number;
  UpdateDate: number;
  TotalPricel: number;
  TotalPricellVat: number;
  CurrencyCode: string;
}

export class Table2 {
  index: number;
  ItemDescription: string;
  Barcode: string;
  PRNumber: string;
  Amount: number;
  Unit: number;
  Price: number;
  TotalPriceItem: number;
  ItemVat: string;
}

export class Search {
  pstatuspi: number;
  pbarcode: string;
  pcreator: number;
  pdatestart: number;
  pdateend: number;
  pmaterial: string;
  pprnumber: string;
  pstatus: number;
  plevelappove: string;
  prowstart: number;
  prowend: number;
  porderby: string;
  pponame: string;
  ppino: string;
  ppono: string;
  psono: string;
  ppodoctypeid: number;
  ppurgroupid: number;
  pplantid: number;
  psupplierid: number;
  pagmid: number;
  pcurrencyid: number;
  psendsupplier: number;
  pgetby: number;
}

export class DROPDOWNLIST {
  PODOCTYPE: Array<PODOCTYPE>;
  PURGROUP: Array<PURGROUP>;
  PLANT: Array<PLANT>;
  SUPPLIER: Array<SUPPLIER>;
  ARGREEMENT: Array<ARGREEMENT>;
  CURRENCY: Array<CURRENCY>;
  ConditionType: Array<ConditionType>;
  PaymentTerm: Array<PaymentTerm>;
  Unit: Array<Unit>;
  StorageLocation: Array<StorageLocation>;
  TaxCodes: Array<TaxCodes>;
  CompannyProfiles: Array<CompanyProfile>;
  Partner: Array<partner>;
  AccountAssigment: Array<AccountAssigment>;
  Userlist: Array<UserModels>;
  constructor() {
    this.PODOCTYPE = new Array<PODOCTYPE>();
    this.PURGROUP = new Array<PURGROUP>();
    this.PLANT = new Array<PLANT>();
    this.SUPPLIER = new Array<SUPPLIER>();
    this.ARGREEMENT = new Array<ARGREEMENT>();
    this.CURRENCY = new Array<CURRENCY>();
    this.ConditionType = new Array<ConditionType>();
    this.PaymentTerm = new Array<PaymentTerm>();
    this.Unit = new Array<Unit>();
    this.StorageLocation = new Array<StorageLocation>();
    this.TaxCodes = new Array<TaxCodes>();
    this.CompannyProfiles = new Array<CompanyProfile>();
    this.Partner = new Array<partner>();
    this.AccountAssigment = new Array<AccountAssigment>();
    this.Userlist = new Array<UserModels>();
  }
}

export class AccountAssigment {
  Code: string;
  Name: string;
  Description: string;
}

export class ConditionType {
  ConditionTypeID: number;
  SupplierID: number;
  ConditionTypeCode: string;
  Description: string;
  UnitCode: string;
  UnitDescription: string;
  CreateDate: number;
  CreateBy: number;
}

export class PODOCTYPE {
  UseRetention: Boolean;
  UseDpPayment: Boolean;
  PODocTypeID: number;
  PRDocTypeID: number;
  ProductTypeID: number;
  PODocTypeGroup: string;
  PODocType: string;
  PODescription: string;
  CreateDate: number;
  CreateBy: number;
}

export class PURGROUP {
  PurGroupID: number;
  PurGroupCode: string;
  Description: string;
  Remark: string;
  Type: string;
  CreateDate: number;
  CreateBy: number;
  UpdateDate: number;
  UpdateBy: number;
  Active: Boolean;
}

export class PLANT {
  PlantID: number;
  PlantCode: string;
  PlantName: string;
  Description: string;
  CreateDate: number;
  CreateBy: number;
  UpdateDate: number;
  UpdateBy: number;
  Active: Boolean;
  Name1: string;
  Name2: string;
  Name3: string;
  Name4: string;
  Address: string;
  Street: string;
  City: string;
  District: string;
  PostalCode: string;
  Country: string;
  Telephone1: string;
  Telephone2: string;
  DistrictEN: string;
  CityEN: string;
  StreetEN: string;
  CountryEN: string;
}

export class SUPPLIER {
  SupplierID: number;
  TAXID: string;
  SupplierCode: string;
  Name1: string;
  Name2: string;
  Name3: string;
  Name4: string;
  Address: string;
  Street: string;
  City: string;
  District: string;
  PostalCode: string;
  Country: string;
  Telephone1: string;
  Telephone2: string;
  PaymentTerm: string;
  SendSupplierFlag: Boolean;
  Status: number;
  CreateBy: number;
  CreateDate: number;
  UpdateBy: number;
  UpdateDate: number;
  SAPLastInterface: number;
  CurrencyCode: string;
  DistrictEN: string;
  CityEN: string;
  StreetEN: string;
  CountryEN: string;
  Name1EN: string;
  Name2EN: string;
}

export class ARGREEMENT {
  Agmid: number;
  Agmno: string;
  Docdate: number;
  Deptcode: string;
  Startdate: number;
  Enddate: number;
  Statuscode: string;
  Createby: number;
  Createdate: number;
  Updateby: number;
  Updatedate: number;
  Suppliername: string;
  Status: number;
  Statusbgcolor: string;
  Statusbordercolor: string;
  Statusfontcolor: string;
  Statusfontsize: number;
  Createbyname: string;
  Updatebyname: string;
}

export class CURRENCY {
  CurrencyID: number;
  CurrencyCode: string;
  Description: string;
  CreateDate: number;
  CreateBy: number;
}

export class PoImportSetting {
  UserId: number;
  Name: string;
  UpdateBy?: number;
}

export class MergeItem {
  Pidocnumber: string;
  PoheaderId: string;
  Createby: number;
}

export class PIStatusUpdate {
  PoheaderId: number;
  UpdateBy: number;
  Reason: string;
}
export class Shippingpoint {
  ShippingPointCode: string;
  ShippingPointName: string;
  varchar: string;
}

export class Unit {
  ItemUnitID: number;
  UnitCode: string;
  Description: string;
}

export class StorageLocation {
  StorageLocationID: number;
  StorageLocationCode: string;
  Description: string;
}

export class TaxCodes {
  TaxCodeID: number;
  TaxCode: string;
  PercentRate: number;
  Description: string;
}

export class POItems {
  Index: number;
  rPRound: number;
  rGLAccount: string;
  rCostCenter: string;
  rProfitCenter: string;
  rAccAssignment: string;
  taxpercent: number;
  rPOProductListInfoRecordID: number;
  InfoRecordNo: string;
  rSupplierID: number;
  rSupplierCode: string;
  rSupplierName: string;
  rPlantID: number;
  rPlantCode: string;
  rPlantName: string;
  rPurGroupID: number;
  rPurGroupCode: string;
  rCurrencyID: number;
  rCurrencyCode: string;
  rPRNumber: string;
  rMaterialNumber: string;
  rMaterialGroup: string;
  rBarcode: string;
  rPurGroup: string;
  rDescription: string;
  rStorageLocation: string;
  rDeliveryDate: Date;
  rPrice: string;
  rDateOfPrice: number;
  rQty: string;
  rUnit: string;
  rVat: number;
  rFreeItem: Boolean;
  rShortText: string;
  rTextLine: string;
  rCreateDate: number;
  rCreateBy: number;
  rUpdateDate: number;
  rUpdateBy: number;
  rActive: number;
  UnitValue: Array<UnitValue>;
  RoundBaseUnit: Array<BaseRounding>;
  constructor() {
    this.UnitValue = new Array<UnitValue>();
    this.RoundBaseUnit = new Array<BaseRounding>();
  }
}

export class PIManageresource {
  Mode: string;
  Type: string;
  PoheaderId: number;
  pretention: string;
  pretentionpercent: number;
  pdpcategory: string;
  pdppaymentpercent: number;
  pdppaymentamount: number;
  pdpduedate: number;
  namepi: string;
  ppurorgnumber: string;
  ppidocnumber: string;
  psappodocnumber: string;
  ppodoctypeid: number;
  ppodoctypegrop: string;
  psapsodocnumber: string;
  ppurgroupid: number;
  ppotitle: string;
  pplantid: number;
  psupplierid: number;
  pagmid: number;
  psendsupplier: number;
  pcurrencyid: number;
  ppaymentterm: string;
  pbillingaddressname: string;
  pbillingaddresstext: string;
  pshippingaddressname: string;
  pshippingaddresstext: string;
  premark: string;
  pupdateby: number;
  pcreateby: number;
  poitem: Array<Poitem_resource>;
  function: Array<Partners>;
  conditions: Array<Conditions>;
  att_document_po: Array<Att_document_po>;
  AddressPlant: Address;
  AddressSupplier: Address;
  PIManageresource() {
    this.poitem = new Array<Poitem_resource>();
    this.conditions = new Array<Conditions>();
    this.att_document_po = new Array<Att_document_po>();
    this.function = Array<Partners>();
    this.AddressPlant = new Address();
    this.AddressSupplier = new Address();
  }
}

export class partner {
  Code: string;
  Name: string;
  Description: string;
}

export class Partners {
  pofunctioncode: string;
  ppartnersuppliercode: string;
}


export class SaveFile {
  att_document: Array<Att_document>;
  public FileUpload() {
    this.att_document = new Array<Att_document>();
  }
}

export class FileUpload {
  att_document: Array<Att_document>;
  public FileUpload() {
    this.att_document = new Array<Att_document>();
  }
}

export class Att_document_po {
  comment_doc_number: string;
  comment_description: string;
  pcreateby: number;
  pisedit: Boolean;
  pupdateby: number;
  pisdelete: boolean;
  poreferdocid: number;
}

export class Att_document {
  comment_doc: string;
  File: any;
  path: string;
  pattachmentfilename: string;
  pcreateby: number;
  readonlys: Boolean;
  pattachmentid: number;
  deleteflag: Boolean;
  pisedit: Boolean;
  pupdateby: number;
}

export class Conditions {
  conditiontype: string;
  conditionvalue: number;
  Currency: string;
  pcreateby: number;
}

/* Send To APi */
export class Poitem_resource {
  pitemretentionpercent: number;
  pitemdpcategory: string;
  pitemdppaymentpercent: number;
  pitemdppaymentamount: number;
  pitemdpduedate: number;
  proundcheck: Boolean;
  paccassignment: string;
  pglaccount: string;
  pround: number;
  pcostcenter: string;
  pvendmat: string;
  psoitemnumber: string;
  pprofitcenter: string;
  checkbox: Boolean;
  pspilt: Boolean;
  taxpercent: number;
  pisdelete: Boolean;
  ppoheaderid: number;
  pinforecordno: string;
  ppoitemnumber: number;
  ppritemnumber: number;
  ppiitemnumber: number;
  pprnumber: string;
  pmaterialnumber: string;
  pmaterialgroup: string;
  pdescription: string;
  pbarcode: string;
  ppurgroupcode: number;
  pstoragelacation: number;
  pdeliverydate: number;
  pprice: number;
  ppriceTemp: number;
  total: number;
  pamount: number;
  punit: string;
  pvat: Boolean;
  vatpercent: number;
  pfreeitem: Boolean;
  pshorttext: string; // **
  ptextline: string;
  pactive: Boolean; // **
  pcreateby: number; // **
  pupdateby: number; // **
}
/* Add Totable */

export class Poitem {
  index: number;
  pitemretentionpercent: number;
  pitemdpcategory: string;
  pitemdppaymentpercent: number;
  pitemdppaymentamount: number;
  pitemdpdate: Date;
  pcurrencyid: string;
  pround: number;
  checkbox: Boolean;
  pvendmat: string;
  psoitemnumber: string;
  pglaccount: string;
  pcostcenter: string;
  pprofitcenter: string;
  pspilt: Boolean;
  taxpercent: number;
  ppoheaderid: number;
  pinforecordno: string;
  ppoitemnumber: number;
  ppritemnumber: number;
  ppiitemnumber: number;
  pprnumber: string;
  pmaterialnumber: string;
  pmaterialgroup: string;
  pbarcode: string;
  ppurgroupcode: string;
  pdescription: string;
  pstoragelacation: string;
  pdeliverydate: Date;
  pprice: number;
  pamount: number;
  punit: string;
  pvat: number;
  pfreeitem: Boolean;
  paccassignment: string;
  pshorttext: string;
  ptextline: string;
  pactive: Boolean;
  pcreateby: number;
  pupdateby: number;
  pisfix: Boolean;
  pplantid: number;
  unitypeddl: Array<UnitValue>;
  proundbaseunit: Array<BaseRounding>;
  constructor() {
    this.unitypeddl = new Array<UnitValue>();
    this.proundbaseunit = new Array<BaseRounding>();
  }
}

export class return_getbyid {
  HeaderPo: POHeaders;
  ItemPo: Array<POItemsbyid>;
  ConditionType: Array<POConditionType>;
  POAttachment: Array<POAttachment>;
  POReferDoc: Array<POReferDoc>;
  PartnerFunction: Array<Partnerfunction>;
  constructor() {
    // this.HeaderPo = new POHeaders();
    this.ItemPo = new Array<POItemsbyid>();
    this.ConditionType = new Array<POConditionType>();
    this.POAttachment = new Array<POAttachment>();
    this.POReferDoc = new Array<POReferDoc>();
    this.PartnerFunction = Array<Partnerfunction>();
  }
}
export class Partnerfunction {
  FunctionCode: string;
  POHeaderID: number;
  POPartnerFunctionID: number;
  PartnerSupplierCode: string;
}

export class POHeaders {

  Retention: string;
  RetentionPercent: number;
  DpCategory: string;
  DpPaymentPercent: number;
  DpPaymentAmount: number;
  DpDueDate: Date;

  CancelReason: string;
  Index: number;
  POHeaderID: number;
  HeaderNote: string;
  TrackingNo: string;
  PRDocTypeID: number;
  PurOrgNumber: string;
  PODocTypeID: number;
  PODocTypeGroup: string;
  POResource: number;
  SupplierID: number;
  ContractID: number;
  PaymentTermID: number;
  PlantID: number;
  AgmID: number;
  CurrencyID: number;
  PurGroupID: number;
  SendSupplier: number;
  PIDocNumber: string;
  SAPPODocNumber: string;
  SAPSODocNumber: string;
  PurGroupCode: string;
  POTitle: string;
  BillingAddressName: string;
  BillingAddressText: string;
  ShippingAddressName: string;
  ShippingAddressText: string;
  ExchangeRateDate: number;
  Remark: string;
  Status: number;
  CreateDate: number;
  CreateBy: number;
  UpdateDate: number;
  UpdateBy: number;
}

export class POItemsbyid {
  RetentionPercent: number;
  DpCategory: string;
  DpPaymentPercent: number;
  DpPaymentAmount: number;
  DpDate: Date;
  Index: number;
  Round: number;
  RoundingCalculate: Boolean;
  GLAccount: string;
  CostCenter: string;
  ProfitCenter: string;
  TaxpercentDisplay: string;
  VendMat: string;
  SoItemNumber: string;
  Taxpercent: number;
  POItemID: number;
  POHeaderID: number;
  PurGroupID: number;
  PlantID: number;
  InfoRecordNo: string;
  POItemNumber: number;
  PRItemNumber: number;
  PIITemnumber: number;
  PRNumber: string;
  MaterialNumber: string;
  MaterialGroup: string;
  Barcode: string;
  PurGroupCode: string;
  Description: string;
  StorageLocation: string;
  DeliveryDate: Date;
  Price: number;
  Qty: number;
  Unit: string;
  Vat: number;
  FreeItem: Boolean;
  AccAssignment: string;
  ShortText: string;
  TextLine: string;
  CreateDate: number;
  CreateBy: number;
  UpdateDate: number;
  UpdateBy: number;
  Active: Boolean;
  Pisfix: Boolean;
  UnitValue: Array<UnitValue>;
  RoundBaseUnit: Array<BaseRounding>;
  constructor() {
    this.UnitValue = new Array<UnitValue>();
    this.RoundBaseUnit = new Array<BaseRounding>();
  }
}

export class POAttachment {
  POAttachmentID: number;
  POHeaderID: number;
  AttachmentFileName: string;
  AttachmentFilePath: string;
  Description: string;
  CreateDate: number;
  CreateBy: number;
  UpdateDate: number;
  UpdateBy: number;
}
export class POReferDoc {
  POReferDocID: number;
  POHeaderID: number;
  ReferDocNo: string;
  Description: string;
  CreateDate: number;
  CreateBy: number;
  UpdateDate: number;
  UpdateBy: number;
}

export class POConditionType {
  POConditionTypeID: number;
  POHeaderID: number;
  ConditionTypeID: number;
  ConditionValue: number;
  CreateDate: number;
  CreateBy: number;
  UpdateDate: number;
  UpdateBy: number;
  Description: string;
  Percen: Boolean;
}

export class ApproveList {
  FlagCheck_LIST_1: Boolean;
  FlagCheck_LIST_2: Boolean;
  FlagCheck_LIST_3: Boolean;
  Approve_LIST_1: Array<ApproveLevel>;
  Approve_LIST_2: Array<ApproveLevel>;
  Approve_LIST_3: Array<ApproveLevel>;
  RejectReason: string;
  constructor() {
    this.Approve_LIST_1 = new Array<ApproveLevel>();
    this.Approve_LIST_2 = new Array<ApproveLevel>();
    this.Approve_LIST_3 = new Array<ApproveLevel>();
  }
}

export class ApproveLevel {
  Index: number;
  rPOApproverID: number;
  rPOApproverLevelID: number;
  rApproverLevelName: string;
  rApproveLevel: number;
  rUserApprove: string;
  rStatus: number;
  rRejectReason: string;
  rApproveDate: Date;
}

export class POApproveFlow {
  rReleaseCode: string;
  rReleaseName: string;
  rUserApprove: string;
}
export class POViewHeader {
  rCancelReason: string;
  rPurOrgNumber: string;
  rPOHeaderID: number;
  rPRDocTypeID: number;
  rPODocTypeID: number;
  rSupplierID: number;
  rContractID: number;
  rPlantID: number;
  rAgmID: number;
  rCurrencyID: number;
  rPurGroupID: number;
  rSendSupplier: number;
  rPIDocNumber: string;
  rSAPPODocNumber: string;
  rSAPSODocNumber: string;
  rPurGroupCode: string;
  rPOTitle: string;
  rBillingAddressName: string;
  rBillingAddressText: string;
  rShippingAddressName: string;
  rShippingAddressText: string;
  rExchangeRateDate: number;
  rRemark: string;
  rStatus: number;
  rCreateDate: number;
  rCreateBy: number;
  rUpdateDate: Date;
  rUpdateBy: number;
  rPlantCode: string;
  rPlantName: string;
  rPlantDescription: string;
  rPRDocType: string;
  rPRDescription: string;
  rPODocType: string;
  rPODescription: string;
  rProductTypeID: number;
  rProductTypeCode: string;
  rProductTypeDescription: string;
  rCurrencyCode: string;
  rCurrencyDescription: string;
  rAgmNo: string;
  rAgmDescription: string;
  rContractNo: string;
  rContractDeptCode: string;
  rContractSubject: string;
  rContractRemark: string;
  rSupplierCode: string;
  rSupplierName: string;
  rPaymentTermID: number;
  rPaymentTermCode: string;
  rDescription: string;
}

export class PurGroup {
  PurGroupID: number;
  UserID: number;
  DeptCode: string;
  DeptName: string;
  PurGroupCode: string;
  Description: string;
  Remark: string;
  FirstName: string;
  LastName: string;
  CreateDate: string;
  CreateBy: number;
  UpdateDate: string;
  UpdateBy: number;
  Active: boolean;
  Status?: number;
}
export class PurGroupSearch {
  PurGroupCode: string;
  Description: string;
}

export class PIAppovalSettingList {
  DeptCode: string;
  PODeptSetApproverID: string;
  POApproverLevelID: string;
  UserApproveID: string;
  GetApproveBy: string;
  CreateDate: string;
  CreateBy: string;
  UpdateDate: string;
  UpdateBy: string;
  DDeptCode: string;
  DDeptName: string;
  LPOApproverLevelID: string;
  ReleaseCode: string;
  Name: string;
  LLevel: string;
  LCreateDate: string;
  LCreateBy: string;
  UUserID: string;
  UserName: string;
  Email: string;
  Title: string;
  FirstName: string;
  LastName: string;
  UDeptCode: string;
  IsVendorFlag: string;
  ShippingPoint: string;
  SalesOrg: string;
  Role: string;
  Level: string;
  ActiveFlag: string;
  UCreateBy: string;
  UCreateDate: string;
  UUpdateBy: string;
  UUpdateDate: string;
  PurchaseOrg: string;
  Position: string;
  Status: string;
}
export class PaymentTerm {
  PaymentTermID: number;
  PaymentTermCode: string;
  Description: string;
  CreateDate: number;
  CreateBy: number;
  UpdateDate: number;
  UpdateBy: number;
}

export class CompanyProfile {
  ID: number;
  CompanyNameTH: string;
  CompanyNameEN: string;
  BranchTH: string;
  BranchEN: string;
  TaxID: string;
  AddressTH: string;
  AddressEN: string;
  DistrictTH: string;
  DistrictEN: string;
  CityTH: string;
  CityEN: string;
  CountryTH: string;
  CountryEN: string;
  PostalCode: string;
  Telephone: string;
  FaxNo: string;
  CreateBy: string;
  CreateDate: string;
  UpdateBy: string;
  UpdateDate: string;
  TelephoneImport: string;
  FaxNoImport: string;
}

export class Address {
  ppoaddressesid: number;
  ppoheaderid: number;
  paddressnumber: string;
  pname1: string;
  pname2: string;
  pname3: string;
  pname4: string;
  paddress: string;
  pstreet: string;
  pcity: string;
  pdistrict: string;
  ppostalcode: string;
  pcountry: string;
  pcountryen: string;
  ptelephone1: string;
  ptelephone2: string;
  ptypeaddress: string;
  pcreateby: number;
  pcreatedate: Date;
  pupdateby: number;
  pupdatedate: Date;
}

export class GetpoAddress {
  ppoid: number;
  ptypeaddress: string;
}

export class RoundUnit {
  Round: number;
  Unit: Array<UnitValue>;
  RoundBaseUnit: Array<BaseRounding>;
  public RoundUnit() {
    this.Unit = new Array<UnitValue>();
    this.RoundBaseUnit = new Array<BaseRounding>();
  }
}

export class UnitValue {
  MaterialUnitID: number;
  MaterialNo: string;
  Unit: string;
  UnitType: string;
  UnitQty: number;
}

export class GetUnitValue {
  MaterialNo: string;
  Supplier: string;
  Plant: string;
  Purgroup: string;
}


export class BaseRounding {
  rUnit: string;
  rUnitType: string;
  rUnitQty: number;
  rRounding: number;
}


export class HistoryLog {
  index: number;
  rActionDate: Date;
  rDescription: string;
  rAction: string;
  rDocNo: string;
  rActionByName: string;
}

export class HistoryValue {
  index: number;
  ActionDate: Date;
  Description: string;
  Action: string;
  DocNo: string;
  ActionByName: string;
}