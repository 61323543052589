import { ContractSignature } from "./Contract.model";

export class TemplateAttrDetail {
    ID: number;
    AgmTemplateID: number;
    AgmTabCode: string;
    AttrCode: string;
    LineNo: number;
    ddlCode: string;
    ddlValue: string;
    ddlName: string;
    UpdateDate: string;
    UpdateBy: number;
    CreateDate: string;
    CreateBy: number;
    NoofColumn: number;
    Unit: string;
    DdlValue: string;
}

export class TemplateAttrDetailSearch {
    TabCode: string;
    AttrCode: string;
}

export class Brand {
    BrandID: number;
    BrandCode: string;
    BrandName: string;
    ItemSubClassCode: string;
}

export class ItemSubClass {
    ItemSubClassID: number;
    ItemSubClassCode: string;
    ItemSubClassName: string;
}
export class POApproverLevel{
  POApproverLevelID: any;
  Name: any;
  Level:number  

}
export class ProductType{
     ProductTypeID :number
     Code: string;
     Description: string;

}
export class POApprover{
     POApproverID  
     ProductTypeID  
     POApproverLevelID  
     CreditLimitedStart  
     CreditLimitedEnd 
    Category  
    ApproveLevel  
     ApproveSupLevel 

}
export class ItemCategory{
    CategoryCode: string;
    CategoryName: string;

}
export class ItemSubCategory{
    SubCategoryCode
    SubCategoryName
    CategoryCode   
}
export class Status {

    TransactionType: number;
    StatusID: number;
    StatusCode: string;
    StatusName: string;
    Description: string;
    StatusBgColor: string;
    StatusBorderColor: string;
    StatusFontColor: string;
    StatusFontSize: number;
}

export class Contract_Search {
    ContractNo: string;
    DeptCode: string;
    StartDate?: string;
    EndDate?: string;
    StatusCode: string;
    Name1: string;
    ActiveFlag: boolean;
}

export class Supplier_Search {
    SupplierCode: string;
    SupplierName: string;
    Active: number;
}


export class Department {
    DeptCode: string;
    DeptName: string;
}


export class SystemConfig {
    ConfigCode: string;
    ConfigValue: string;
    ConfigDisplay: string;
  SeqID: unknown;
}


export class AgmSignature {
    ID: number;
    AgmID: number;
    LineNo: number;
    VendorFlag: number;
    Name: number;
    Position: number;
    SignatureType: number;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}

export class AgmAttachment {
    ID: number;
    AgmID: number;
    LineNo: number;
    Decription: string;
    Path: string;
    FileName: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}

export class Supplier_List {  //Old
    SupplierID: number; 
    tAXID: string;
    Name1: string;
    Name2: string;
    Name3: string;
    Name4: string;
    SupplierCode: string;
    Address: string;
    Street: string;
    City: string;
    District: string;
    PostalCode: string;
    Country: string;
    Telephone1: string;
    Telephone2: string;

}
export class Supplier { //New
    SupplierID
    TAXID
    SupplierCode
    Name1
    Name2
    Name3
    Name4
    Address
    Street
    City
    District
    PostalCode
    Country
    Telephone1
    Telephone2
    PaymentTerm
    SendSupplierFlag
    Status
    CreateBy
    CreateDate
    UpdateBy
    UpdateDate
    SAPLastInterface
    SupplierFullname
    Contract_Signature?: ContractSignature[];
}


export class SystemAttachDoc {
    SystemAttachDocID: number;
    LineNo: number;
    DocName: string;
    Description: string;
    Path: string;
    FileName: string;
    CreateBy: number;
    CreateDate: Date;
    UpdateBy: number;
    UpdateDate: Date;
    bool?:boolean;
}
export class UserByPermission {
    UserId: string;
    ObjId: string;
    PermissionId: number;
}