import { AbstractControl } from '@angular/forms';

export class BumBimSmartCustomValidator {
  // Number only validation
  static numeric(control: AbstractControl) {
    let val = control.value;
    console.log('control.value', val);
    if (val === null || val === '') { return null; }
    val = val.toString().replace(',', '');
    if (!val.toString().match(/^[0-9]+([\.,][0-9]+)*?$/)) {
      return {
        'invalidNumber': true
      };
    }
    return null;
  }
}
