// import * as $ from "jquery";

// declare var toastr: any;
// declare var jQuery: any;
// declare var $: any;
// declare var Clipboard: any;
export enum EnumSignatureTypes  {
  Signer = "1",
  Witness= "2",
}
export enum EnumOperationStatus {
  Added = 1,
  Modified = 2,
  Removed = 3,
  Nothing = 4
}
export enum EnumOperationStatus {
  //Contract
  Draft = 100,
  Approved = 300,
  Cancelled = 2000,
  Rejected = 350,
  PendingApprove = 200,
  Active = 500,
  Expired = 1900,
  Renew = 1500,

  
  TemplateActive = 300,
}
export enum EnumOperationAgmTemplateStatus {
  Draft = 100,
  Approved = 200,
  
}
export enum EnumOperationAgmStatus {
  Draft = 100,
  Approved = 300,
  Cancelled = 2000,
  Rejected = 350,
  PendingApprove = 200,
  Active = 500,
  Renew = 1500,
  Expired = 1900
}
export class TransactionType{
  Contractlist: 100
}
export enum EnumAgmOtherType {
  Store = "Store",
  Prod = "Prod",
  CreditTerm = "CreditTerm",
  Customize = "Customize",
  Customize2 = "Customize2"
}

export var fullDateString: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
export var fullMonthString: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
