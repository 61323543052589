import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
export class AgmSelectedTab
{
    AgmID: number;
    CreditFlag: boolean;
    RebateFlag: boolean;
    MarketingFeeFlag: boolean;
    DamageFlag: boolean;
    DCChargeFlag: boolean;
    CustomizeFlag: boolean;
    NewStoreFlag: boolean;
    NewProductFlag: boolean;
    CreditTermFlag: boolean;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
    Customize2Flag: any;
}

export class AgmSignatureList{
    AgmID: number;
    AgmNo: string;
    Suppliername: string;
    SupplierCode: string;
    DocDate: string;
    DeptCode: string;
    StartDate: string;
    EndDate: string;
    StatusCode: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
    Status: string;
    Statusbgcolor: string;
    Statusbordercolor: string;
    Statusfontcolor:string;
    Statusfontsize: number;
}
export class AgreementList {
  AgmID: number;
  AgmNo: string;
  Auppliername: string;
  SupplierCode: string;
  DocDate: string;
  DeptCode: string;
  StartDate: string;
  EndDate: string;
  StatusCode: string;
  CreateBy: number;
  CreateDate: string;
  UpdateBy: number;
  UpdateDate: string;
  Status: string;
  Statusbgcolor: string;
  Statusbordercolor: string;
  Statusfontcolor: string;
  Statusfontsize: number;
  CreateByName: string;
  UpdateByName: string;
}
export class AgreementList_Search {
    AgmNo: string;
    Status: string;
    RefContractNo: string;
    DeptCode: string;
    SupCodeName: string;
    RefAgmNo:string;
    SetstartDate:string;
    SetEndDate:string;
    AgmType:string;
    ActiveFlag:boolean;
    ApproveUserId:number;
}


export class  AgmAprroval {
    ActionName: string;
    Action: string;
    ActionDate?: string;
    Status: number;
    StatusName: string;
    CompleteFlag: number;
    Seq: number;
}

export class  AgmHeader {
  FooterSpacing: any[];
  IncVat: boolean;
  HeaderApproveStatus: any;
  CancelReason: any;
  constructor(){
      this.AgmSignature =new Array<AgmSignature>();
      this.AgmAttachment=new Array<AgmAttachment>();
      this.AgmCredit=new Array<AgmCredit>();
      this.AgmRebate=new Array<AgmRebate>();
      this.AgmMarketingFee=new Array<AgmMarketingFee>();
      this.AgmDCCharge=new AgmDCCharge();
      this.AgmDamage=new AgmDamage();
      this.AgmOther=new Array<AgmOther>();
  }
    AgmID: number;
    AgmNo: string;
    Description: string;
    DocDate: string;
    DeptCode: string;
    DeptName?:string;
    StartDate: string;
    EndDate: string;
    Status: number;
    StatusCode: string;
    StatusName: string;
    ReferenceContractID?: number;
    ReferenceContractNo: string;
    AgmType: string;
    ReferenceAgmID: number;
    ReferenceAgmNo: string;
    Subject: string;
    Remark: string;
    SupplierID: number;
    SupplierCode: string;
    SendSupplierFlag: boolean;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
    SubmitBy?: number;
    SubmitDate?: string;
    ManagerApproveStatus?: number;
    SupplierApproveStatus?: number;
    SupplierApproveBy?: number;
    SupplierApproveDate?: string;
    RejectedReason: string;
    RejectedBy?: number;
    RejectDate?: string;
    SupplierName: string;
    ManagerApproveStatusCode: string;
    ManagerApproveStatusName: string;
    SupplierApproveStatusCode: string;
    SupplierApproveStatusName: string;
    AgmSelectedTab: AgmSelectedTab;
    AgmSignature: AgmSignature[];
    AgmAttachment: AgmAttachment[];
    AgmCredit: AgmCredit[];
    AgmRebate: AgmRebate[];
    AgmMarketingFee: AgmMarketingFee[];
    AgmDCCharge: AgmDCCharge;
    AgmDamage: AgmDamage;
    AgmOther: AgmOther[];
    AgmItem: AgmItem[];
  Mode: string;
}
export class UpdateStatusAgreement {
  AgmtId: string;
  StatusID: string;
  Cancelreason:string;

}



export class  AgmSignature {
    ID: number;
    AgmID: number;
    LineNo: number;
    VendorFlag: boolean;
    Name: string;
    Position: string;
    SignatureType: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
  SCGUserID: number;
  Email: string;
}

export class  AgmAttachment {
    ID: number;
    AgmID: number;
    LineNo: number;
    Decription: string;
    Path: string;
    FileName: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}

export class  AgmCredit {
    AgmCreditID: number;
    AgmID: number;
    Year: string;
    Period: string;
    CateCode:string
    CateName:string
    SubCateCode:string;
    SubCateName:string;
    Value1: number;
    Value2: number;
    Value3: number;
    Value4: number;
    Value5: number;
    Value6: number;
    Value7: number;
    Value8: number;
    Value9: number;
    Value10: number;
    Value11: number;
    Value12: number;
    ManagerApproveID?: number;
    ManagerRejectReason: string;
    SupplierRejectReason: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
  PeriodName: any;
  NoofColumn: number;
  StatusCode: any;
  StatusBgColor: any;
  StatusBorderColor: any;
  StatusFontSize: any;
  ManagerApproveStatus: any;
  StatusFontColor: any;
  SubCategoryName: string;
}

export class  AgmRebate {
    AgmRebateID: number;
    AgmID: number;
    RebateNo: string;
    RebateType: string;
    SupType: string;
    CalBy: string;
    CalByName: string;
    Period: string;
    PeriodName: string;
    PaymentMethod: string;
    PaymentMethodName: string;
    ManagerApproveID?: number;
    ManagerRejectReason: string;
    SupplierRejectReason: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
    RebateStep: AgmRebateStep[];
    SubItems: AgmItem[];
    StatusCode: any;
    StatusBgColor: any;
    StatusBorderColor: any;
    StatusFontSize: any;
    ManagerApproveStatus: any;
    StatusFontColor: any;
}

export class  AgmMarketingFee {
    AgmMarketingFeeID: number;
    AgmID: number;
    Description: string;
    MKTLineNo: number;
    SupType: string;
    SupTypeName: string;
    CalBy: string;
    CalByName: string;
    SupAmount: number;
    TargetAmount: number;
    Period: string;
    PeriodName: string;
    PaymentMethod: string;
    PaymentMethodName: string;
    ManagerApproveID?: number;
    ManagerRejectReason: string;
    SupplierRejectReason: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
  StatusCode: any;
  StatusBgColor: any;
  StatusBorderColor: any;
  StatusFontSize: any;
  ManagerApproveStatus: any;
  StatusFontColor: any;
}

export class  AgmDCCharge {
    AgmDCChargeID: number;
    AgmID: number;
    SupType: string;
    SupTypeName: string;
    CalBy: string;
    CalByName: string;
    SupAmount: number;
    Period: string;
    PeriodName: string;
    PaymentMethod: string;
    PaymentMethodName: string;
    Note: string;
    ManagerApproveID?: number;
    ManagerRejectReason: string;
    SupplierRejectReason: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}

export class  AgmDamage {
    AgmDamageID: number;
    AgmID: number;
    SupType: string;
    SupTypeName: string;
    CalBy: string;
    CalByName: string;
    SupAmount: number;
    Note: string;
    ManagerApproveID?: number;
    ManagerRejectReason: string;
    SupplierRejectReason: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}
export class  AgmItem{
  AgmItemID:number
  AgmID:number
  ItemLineNo:number
  CateCode:string
  SubCateCode:string
  SubCateName: any;
  CateName: any;
}
export class  AgmOther {
    AgmOtherID: number;
    AgmID: number;
    OtherType: string;
    Description: string;
    SupType: string;
    SupTypeName: string;
    CalBy: string;
    CalByName: string;
    SupAmount: number;
    PaymentMethod: string;
    PaymentMethodName: string;
    OldCreditTerm?: number;
    NewCreditTerm?: number;
    ManagerApproveID?: number;
    ManagerRejectReason: string;
    SupplierRejectReason: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
  Unit: any;
  Title: string;
  StatusCode: any;
  StatusBgColor: any;
  StatusBorderColor: any;
  StatusFontSize: any;
  ManagerApproveStatus: any;
  StatusFontColor: any;
  Note: string;
}

export class  AgmRebateStep {
    AgmRebateStepID: number;
    AgmRebateID: number;
    AgmID: number;
    StepLineNo: number;
    StepAmount: number;
    TargetAmount: number;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}

export class  AgmRebateItem {
    AgmRebateItemID: number;
    AgmRebateID: number;
    AgmID: number;
    ItemLineNo: number;
    CateCode: string;
    SubCateCode: string;
    SubCateName: string;
    CateName: string;
    CreateBy: number;
    CreateDate: string;
    UpdateBy: number;
    UpdateDate: string;
}
export class ApprovAgmModel
{
    agmId: number;
    AgmNo: string;
    tabCode : string;
    updateby?: number;

    ApprovAgmDetail:ApprovAgmDetail[];
}
export class ApprovAgmDetail
{
  agmId: number;
  AgmNo: string;
  tabCode : string;
  approveflag: boolean;
  rejectreason: string;
  updateby?: number;
}